import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellerOrdersComponent } from './seller-orders.component';
import { SharedOrdersModule } from '../components/shared-order.module';
import { SellerOrdersRoutingModule } from './seller-orders-routing.module';



@NgModule({
  declarations: [
    SellerOrdersComponent
  ],
  imports: [
    CommonModule,
    SellerOrdersRoutingModule,
    SharedOrdersModule
  ],
  exports: [
    SellerOrdersComponent
  ]
})
export class SellerOrdersModule { }
