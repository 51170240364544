import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card.component';
import { ShareButtonModule } from 'src/app/feature/share-button/share-button.module';
import { RouterModule } from '@angular/router';
import { StarRatingModule } from '../../star-rating/star-rating.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [ProductCardComponent],
  imports: [
    CommonModule,
    ShareButtonModule,
    StarRatingModule,
    RouterModule,
    SharedModule,
    NgbModule
  ],
  exports: [
    ProductCardComponent
  ]
})
export class ProductCardModule { }
