<div class="card" *ngIf="product" [routerLink]="['/product/', product.id]">
  <div class="product-thumb">
    <a >
      <img *ngIf="product.variants | productImages; let url; else noImage"
        [src]="url"
        class="product-card__image"
        [ngClass]="{'product-card--fixed-height' : fixedHeight}"
        alt="" >
      <ng-template #noImage>
        <img [src]="['assets/images/common/no-product-image.png']" class="product-card__image" alt="">
      </ng-template>
    </a>
    <ul class="product-badges">
      <li><span class="badge badge-dark">{{badgeTag}}</span></li>
    </ul>
  </div>
  <div class="card-inner p-3 text-left">
    <ul class="product-name">
      <li  [ngbTooltip]="product.productName" >{{product.productName}}</li>
  </ul>
    <div class="mt-1 text-muted" role="button" [routerLink]="['/company/', product.companyId]">Seller/{{ product.sellerName}}</div>
    <div class="d-flex justify-content-between mt-1">
      <div>
        <a class="price text-primary">${{product.variants[0].price}}</a>
        <div>MOQ: {{ product.variants[0].sku ? product.variants[0].sku.minimumQuantity : 0 }}</div>
      </div>
      <div class="text-right">
        <div class="d-flex justify-content-right align-items-baseline">
          <a class="btn btn-icon p-0"><em class="icon ni ni-cart"></em></a>
          <app-share-button
              [url]="'http://supplyx.prod.s3-website.us-east-2.amazonaws.com/products/product-description/' + product.id.toString()"
            >
            </app-share-button>
          <a class=" btn btn-icon p-0"><em class="icon ni ni-heart"></em></a>
        </div>
        <!-- <app-star-rating></app-star-rating> -->
        <div>
          <!-- <div class="text-muted">0 K pcs sold</div> -->
        </div>
      </div>
    </div>
  </div>
</div>