import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader.component';
import { NgxDropzoneModule } from 'ngx-dropzone';

export const COMPONENTS = [FileUploaderComponent]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    NgxDropzoneModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class FileUploaderModule { }
