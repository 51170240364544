import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NkHeaderComponent } from './nk-header.component';

const COMPS = [NkHeaderComponent]

@NgModule({
  declarations: [...COMPS],
  imports: [
    CommonModule
  ],
  exports: [
    ...COMPS
  ]
})
export class NkHeaderModule { }
