<div class="container">
  <h3 class="nk-block-title page-title ">Orders</h3>
  <app-order-tabs (change)="changeActiveTab($event)">
    <ng-container *ngIf="sellerOrderView$ | async; let sellerOrderView">
      <app-seller-order-table 
        *ngIf="sellerOrderView.orders.length; else noOrders"
        [orders]="sellerOrderView.orders" 
        [user]="sellerOrderView.user"
        (action)="orderAction($event)">
      </app-seller-order-table>
    </ng-container>
  </app-order-tabs>
</div>

<ng-template #noOrders>
    <p class="text-soft">You have no any orders yet</p> 
</ng-template>
