import { ApiResponse, PaginationOptions } from 'src/app/shared/models/auth/api-response.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apartment, Building, Project, TakeOff } from '../../models/projects/project.model';
import { ApiPaginationResponse } from '../../models/auth/api-response.model';
import { environment } from 'src/environments/environment';
import { PaginationResponse } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ProjectAttachment, ProjectImage } from '../../models/product/image.model';
import { IProjectBudget } from '../../models/projects/project-budget.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  refreshProject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshProjectsList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private _http: HttpClient) { }

  fetchProjects(paginationOptions: PaginationOptions): Observable<PaginationResponse<Project>>{
    return this._http.post<ApiResponse<ApiPaginationResponse<Project>>>(`${environment.apiRoot}/${environment.fetchProjects}`, 
    {
      ...paginationOptions,
      page: paginationOptions.page - 1
    }).pipe(
       map((res: ApiResponse<ApiPaginationResponse<Project>>) => {
        return {
          currentPage: paginationOptions.page,
          perPage:  paginationOptions.take,
          total: res.data.total,
          lastPage: Math.ceil(res.data.total/paginationOptions.take),
          data: res.data.data
        }})
     )
  }

  getProjectById(id: number): Observable<ApiResponse<Project>> {
    return this._http.get<ApiResponse<Project>>(`${environment.apiRoot}/${environment.findProjectById.replace(':id', id.toString())}`);
  }

  addProject(project: Project): Observable<ApiResponse<Project>> {
    return this._http.post<ApiResponse<Project>>(`${environment.apiRoot}/${environment.addProject}`, 
    project )
  }

  editProject(project: Project, id: number): Observable<ApiResponse<Project>> {
    delete project.createdAt;
    delete project.updatedAt;
    return this._http.post<ApiResponse<Project>>(`${environment.apiRoot}/${environment.editProject.replace(':id', id.toString())}`,project )
  }

  addBuildingToProject(building: Building): Observable<ApiResponse<Building>>{
    return this._http.post<ApiResponse<Building>>(`${environment.apiRoot}/${environment.addBuilding}`, building );
  }

  addApartmentToProject(apartment: Apartment): Observable<ApiResponse<Apartment>> {
    return this._http.post<ApiResponse<Apartment>>(`${environment.apiRoot}/${environment.addApartment}`, apartment );
  }

  deleteApartmentInProject(id: Number): Observable<ApiResponse<Number>> {
    return this._http.delete<ApiResponse<Number>>(`${environment.apiRoot}${environment.deleteApartment}/${id}`);
  }

  updateProjectTakeOffStatus(id: number, status: string,userId:number ): Observable<ApiResponse<Project>> {
      return this._http.post<ApiResponse<Project>>(`${environment.apiRoot}/${environment.updateprojectstatus.replace(':id', id.toString())}`, 
      { status: status,userId })
  }

  addTakeOffToProject(takeoff: TakeOff): Observable<ApiResponse<TakeOff>>{
    return this._http.post<ApiResponse<TakeOff>>(`${environment.apiRoot}/${environment.takeoff}`,  takeoff );
  }

  addTakeOffToInitiater(takeoff: ProjectAttachment): Observable<ApiResponse<ProjectAttachment>>{
    return this._http.post<ApiResponse<ProjectAttachment>>(`${environment.apiRoot}/${environment.takeoffinitiated}`,  takeoff );
  }

  addImageToProject(image: ProjectImage): Observable<ApiResponse<ProjectImage>>{
    return this._http.post<ApiResponse<ProjectImage>>(`${environment.apiRoot}/${environment.image}`, image );
  }

  fetchTakeOffProjects(paginationOptions: PaginationOptions): Observable<PaginationResponse<Project>>{
    return this._http.post<ApiResponse<ApiPaginationResponse<Project>>>(`${environment.apiRoot}/${environment.takeoffProject}`, 
    {
      ...paginationOptions,
      page: paginationOptions.page - 1
    }).pipe(
       map((res: ApiResponse<ApiPaginationResponse<Project>>) => {
        return {
          currentPage: paginationOptions.page,
          perPage:  paginationOptions.take,
          total: res.data.total,
          lastPage: Math.ceil(res.data.total/paginationOptions.take),
          data: res.data.data
        }})
     )
  }

  getProjectBudget(projectId: number): Observable<ApiResponse<IProjectBudget>> {
    return this._http.get<ApiResponse<IProjectBudget>>(`${environment.apiRoot}/${environment.projectBudget.replace(':id', projectId.toString())}` )
  }
}
