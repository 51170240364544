    <ngx-dropzone (change)="onSelect($event)" class="upload-zone">
	<ngx-dropzone-label>
        <img src="/assets/images/icons/upload.svg" height="50" width="60" alt=""> <br>
        Drap and drop file or click here to upload
    </ngx-dropzone-label>
    <ng-container *ngFor="let f of files">
        <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="isRemoval" (removed)="onRemove(f)" *ngIf="preview && f.type!== 'application/pdf'">
            <ngx-dropzone-label></ngx-dropzone-label>
        </ngx-dropzone-image-preview> -->
        <!-- <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" [file]="f" [removable]="isRemoval" (removed)="onRemove(f)" *ngIf="preview && f.type === 'video/mp4'">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-video-preview> -->
        <div ngProjectAs="ngx-dropzone-preview" *ngIf="preview && f.type === 'application/pdf'">
            <ngx-dropzone-label><!-- {{ f.name }} ({{ f.type }}) --></ngx-dropzone-label>
        </div>
    </ng-container>
</ngx-dropzone>