<div [ngClass]="fullWidth ? 'container-fluid' : 'container'">
    <div class="nk-content-inner">
        <div class="nk-content-body">
            <div class="nk-block-head nkblock-head-sm">
                <div class="nk-block-between g-3">
                    <div class="nk-block-head-content">
                        <h3 class="nk-block-title page-title">
                            {{ label }}
                        </h3>
                        <div class="nk-block-des text-soft">
                            <p> {{ description }}</p>
                        </div>
                    </div><!-- nk-block-head-content -->
                    <div class="nk-block-head-content">
                        <ng-content select="[action]"></ng-content>
                    </div>
                </div>
            </div>
            <!-- nk-block is inside the table component -->
            <div class="nk-block">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>