
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '../../models/product/product.model';
import { ApiPaginationResponse, ApiResponse, PaginationOptions } from '../../models/auth/api-response.model';
import { PaginationResponse } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ProductUpload } from '../../models/product/product-upload.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private _http:HttpClient) { }

  fetchProducts(paginationOptions: PaginationOptions): Observable<PaginationResponse<Product>>{
    return this._http.post<ApiResponse<ApiPaginationResponse<Product>>>(`${environment.apiRoot}/${environment.getProducts}`,
    {
      ...paginationOptions,
      page: paginationOptions.page
    }).pipe(
       map((res: ApiResponse<ApiPaginationResponse<Product>>) => {
        return {
          currentPage: paginationOptions.page,
          perPage:  paginationOptions.take,
          total: res.data.total,
          lastPage: Math.ceil(res.data.total/paginationOptions.take),
          data: res.data.data
        }})
     )
  }

  addDraftProduct(product: Product): Observable<ApiResponse<Product>> {
      return this._http.post<ApiResponse<Product>>(`${environment.apiRoot}/${environment.addDraftProduct}`,
      product)
  }

  addProduct(product: Product): Observable<ApiResponse<Product>> {
      return this._http.post<ApiResponse<Product>>(`${environment.apiRoot}/${environment.addProduct}`,
      product)
  }

  deleteProduct(productId: number ): Observable<ApiResponse<Product>> {
      return this._http.delete<ApiResponse<Product>>(`${environment.apiRoot}/${environment.updateProductByStatus.replace(':id', productId.toString())}`)
  }

  updateProductByStatus(productId: number, status: string ): Observable<ApiResponse<Product>> {
      return this._http.post<ApiResponse<Product>>(`${environment.apiRoot}/${environment.updateProductByStatus.replace(':id', productId.toString())}`,
      { status: status })
  }

  updateProductQuantity(productId: number, quantity: number ): Observable<ApiResponse<Product>> {
      return this._http.post<ApiResponse<Product>>(`${environment.apiRoot}/${environment.updateProductQuantity.replace(':id', productId.toString())}`,
      { availableQuantity: quantity })
  }

  findProductById( productId: number) : Observable<ApiResponse<Product>> {
      return this._http.get<ApiResponse<Product>>(`${environment.apiRoot}/${environment.getProductById.replace(':id', productId.toString())}`)
  }

  
  getAllProductsByKey(value:string): Observable<ApiResponse<Product[]>> {
    return this._http.get<ApiResponse<Product[]>>(`${environment.apiRoot}/${environment.getAllProductsByKey}/${value}`)
  }

  bulkProductUpload(products: Array<ProductUpload>): Observable<ApiResponse<string>> {
    return this._http.post<ApiResponse<string>>(`${environment.apiRoot}/${environment.buldProductUpload}`, products)
  }


}
