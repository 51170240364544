import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UploaderResponse } from 'src/app/shared/models/files/uploader-response';
import { FileUploaderService } from 'src/app/shared/services/api/file-uploader.service';

export interface IUploadFile{
  file?: File,
  error?: IUploadError
}

export interface IUploadError{
  name: string,
  errorMessage: string
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  files: File[] = [];
  filePaths: UploaderResponse[] = [];
  @Input() isRemoval: boolean = true;
  @Input() preview: boolean = true;
  @Output() uploadedFiles: EventEmitter<UploaderResponse[]> = new EventEmitter<UploaderResponse[]>();
  @Output() onRemoved: EventEmitter<UploaderResponse> = new EventEmitter<UploaderResponse>();
  @Output() uploadedFile: EventEmitter<UploaderResponse> = new EventEmitter<UploaderResponse>();

  constructor(private _fileUploader: FileUploaderService) { }

  ngOnInit(): void {
  }

  onSelect(event: { addedFiles: File[]; }) {
    this.files.push(...event.addedFiles);
    this.uploadFiles([...event.addedFiles])
  }

  onRemove(event: File) {
    let index: number = this.files.indexOf(event);
    let removedFile: UploaderResponse = this.filePaths[index];
    this._fileUploader.deleteFile(this.filePaths[index]?.key).pipe(
      tap( () => {
        this.files.splice(this.files.indexOf(event), 1);
        this.filePaths.splice(index, 1);
        this.onRemoved.emit(removedFile);
      })
    ).subscribe();
  }

  uploadFiles(files: File[]) {
    let formData = new FormData();
    files.forEach( file => formData.append('upload', file));
    return this._fileUploader.uploadFile(formData).pipe(
      tap( (res: { data: UploaderResponse[]}) => {
        this.filePaths.push(...res.data);
        this.uploadedFiles.emit(this.filePaths)
      })
      ).subscribe();
  }


  reset() {
    this.filePaths = [];
    this.files = [];
  }

}
