import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { Observable } from "rxjs";
import { Product } from "src/app/shared/models/product/product.model";
import { ProductPaginationStore, ProductsState } from "./product.store";

@Injectable({
    providedIn: 'root'
})

export class ProductPaginationQuery extends QueryEntity<ProductsState, number> {

  products$: Observable<any[]> = this.select( (state) => state.products );
  reservedProducts$: Observable<any[]> = this.select( (state) => state.reservedProducts );

    constructor(protected _store: ProductPaginationStore) {
        super(_store);
    }

}
