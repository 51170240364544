import { Injectable } from '@angular/core';
import { User } from '../../models/auth/user.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setSession( user: User, overrideToken: boolean = true){
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('roleType', user.roleType);
    localStorage.setItem('isLoggedIn', 'true');
    if(overrideToken) {
      localStorage.setItem('token', user.token);
    }
  }

  
  clearSession() {
    localStorage.clear();
  }
}
