import { Injectable } from "@angular/core";
import { EntityState, EntityStore, ID, StoreConfig } from "@datorama/akita";
import { Product } from "src/app/shared/models/product/product.model";

export type ProductPaginationState = EntityState<Product, ID>;


export interface ProductsState {
  products: Product[],
  reservedProducts: Product[],
  error: string | null,
  loading: boolean
}

export const initialProductsState = () => ({
  products: [] as Product[],
  error: null,
  loading: false
})


@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: 'product-pagination-store' })
export class ProductPaginationStore extends EntityStore<ProductsState> {
    constructor() {
        super();
    }
}
