import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { Project } from "src/app/shared/models/projects/project.model";
import { ProjectPaginationStore } from "./project.store";

@Injectable({
    providedIn: 'root'
})
export class ProjectPaginationQuery extends QueryEntity<Project, number> {

    constructor(protected _store: ProjectPaginationStore) {
        super(_store);
    }

}