import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

//Password Vlidation

/* export function PasswordValidator(control: AbstractControl) {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
 
    return password && confirmPassword && 
        password.value != confirmPassword.value ? { mismatch: true } : null;

} */


export const PasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    if(password?.pristine || confirmPassword?.pristine){
        return null;
    }
    return password && confirmPassword && 
       password.value !== confirmPassword.value ? { mismatch: true } : null;

}