import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nk-header',
  templateUrl: './nk-header.component.html',
  styleUrls: ['./nk-header.component.scss']
})
export class NkHeaderComponent implements OnInit {

  @Input() fullWidth: boolean = true;
  @Input() label: string = '';
  @Input() description: string = ''

  constructor() { }

  ngOnInit(): void {
  }

}
