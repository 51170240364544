import { Component } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { OrderStatus } from 'src/app/enums/order-status';
import { ApiResponse } from 'src/app/shared/models/auth/api-response.model';
import { User } from 'src/app/shared/models/auth/user.model';
import { OrderItem } from 'src/app/shared/models/orders/order.model';
import { OrdersService } from 'src/app/shared/services/api/orders.service';
import { SessionQuery } from 'src/app/state/session.query';
import { IOrderTab, ORDER_TABS } from '../components/order-tabs/order-tabs.component';
import { IOrderItemAction } from '../components/order-item-card/order-item-card.component';
import { ToastrService } from 'ngx-toastr';

export interface SellerOrderView {
  orders: Array<OrderItem>;
  user: User | null;
}

@Component({
  selector: 'app-seller-orders',
  templateUrl: './seller-orders.component.html',
  styleUrls: ['./seller-orders.component.scss']
})
export class SellerOrdersComponent {

  user$: Observable<User | null> = this._sessionQuery.user$;
  activeTab$: BehaviorSubject<IOrderTab> = new BehaviorSubject<IOrderTab>(ORDER_TABS[0]);
  sellerOrderView$ = this.getSellerOrdersView();

  fallBackResponse: Observable<ApiResponse<Array<OrderItem>>> = of({
    data: [],
    message: ''
  });

  constructor(private _orderService: OrdersService,
    private _sessionQuery: SessionQuery,
    private _toaster: ToastrService) {}

  getOrders(status: OrderStatus): Observable<Array<OrderItem>> {
    return combineLatest([this._orderService.refreshOrders$]).pipe(
      switchMap(() => this._orderService.getOrderByStatusForSeller(status) ),
      map( apiResponse => apiResponse.data),
      catchError((err) => {
        throwError(err);
        return of([])
      })
    );
  }

  changeActiveTab(status: IOrderTab) {
    this.activeTab$.next(status);
  }

  getSellerOrdersView(): Observable<SellerOrderView> {
    return this.activeTab$.pipe(
      switchMap( activeTab => combineLatest([this._sessionQuery.user$, this.getOrders(activeTab.status)])),
      map(([ user, orders]) => ({
        user, orders
      }))
    )
  }

  orderAction(orderAction: IOrderItemAction) {
    this._orderService.executeOrder([orderAction.item.id], orderAction.status).pipe(
      take(1),
      tap(() => this._orderService.refreshOrders$.next(true)),
      catchError((err) => {
        tap(() => this._toaster.error('Failed to perfrom action!')),
        throwError(err);
        return of([])
      })
    ).subscribe();
  }

}
