import { Injectable } from "@angular/core";
import { EntityState, EntityStore, ID, StoreConfig } from "@datorama/akita";
import { Project } from "src/app/shared/models/projects/project.model";

export type ProjectPaginationState = EntityState<Project, ID>;

@Injectable({
    providedIn: 'root'
})
@StoreConfig({ name: 'project-pagination-store' })
export class ProjectPaginationStore extends EntityStore<Project> {
    constructor() {
        super();
    }
}