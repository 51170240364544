import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductVariant } from 'src/app/shared/models/product/product.model';


@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {

  @Input() product: Product | null = null;
  @Input() fixedHeight: boolean = true;
  @Input() badgeTag: string = '';
   constructor( private router: Router) { }

 /*  navigateProduct(id: number) {
    this.router.navigateByUrl(`/product/${id}`);
  } */

}
